function ChatAssignDropdown ({ assignStatus, lang, business_data, roomStatus, toast, chatTransferButton }) {

  let isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
  const [assigning, setAssigning] = useState(false);

    return (
        <>
        <div
            className={`${!assignStatus ? "ng-hide" : ""}`}
            style={{
              width: "100%",
              borderBottom: "1px solid #E1E7F2",
              margin: "-18px 0 8px",
              paddingBottom: "10px",
            }}
          >
            <div
              className="sp-col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className={`sp-text`}
                  style={{ marginBottom: "8px", marginTop: "8px" }}
                >
                  {lang.__chat_assigned}{" "}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="sp-dropdown sp-row -align-middle"
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    className="dropdown-button dropdown-toggle sp-row assign-box"
                    data-toggle={isSmallScreen ? "modal" : `dropdown`}
                    data-target="#assignModal"
                    aria-haspopup="true"
                    style={{
                      marginBottom: "0px",
                      height: "42px",
                      justifyContent: "space-between",
                      fontSize: "smaller",
                      borderRadius: "6px",
                    }}
                  >
                    {assigning ? (
                      <div class="sp-text-help">
                        <i class="icon icon-circle-notch animate-spin"></i>
                        <span>{lang.__chat_assigning}</span>
                      </div>
                    ) : roomStatus?.assign_to ? (
                      <>
                        <div
                          className="sp-logo -sm"
                          style={{
                            backgroundImage: `url(${
                              roomStatus?.assign_to?.img_profile ||
                              "../public/img/default_profile_img.png"
                            })`,
                          }}
                        ></div>
                        <p
                          className="sp-text -ellipsis"
                        >
                          {roomStatus?.assign_to?.displayname || "-"}
                        </p>
                      </>
                    ) : !_.isEmpty(roomStatus?.assign_team) &&
                      roomStatus?.assign_team?.team_id !== "reception" ? (
                      <>
                        <div
                          className="sp-logo -sm"
                          style={{
                            backgroundImage: `url(${"../public/img/team.png"})`,
                          }}
                        ></div>
                        <p
                          className="sp-text -ellipsis"
                        >
                          {roomStatus?.assign_team?.name || "-"}
                        </p>
                      </>
                    ) : (
                      <div
                        className="sp-text -ellipsis"
                      >
                        {lang.__send_to}
                      </div>
                    )}
                    <i
                      className="icon icon-angle-down"
                      style={{ fontSize: '14px', marginLeft: "10px", marginRight: 'unset' }}
                    ></i>
                  </div>
                  <div
                    className="dropdown-menu"
                    style={{
                      right: "0",
                      left: "initial",
                      width: "100%",
                      zIndex: "9999",
                      maxHeight: "calc(100dvh)",
                    }}
                  >
                    <SelectAgent
                      lang={lang}
                      business_data={business_data}
                      roomStatus={roomStatus}
                      setAssigning={setAssigning}
                      toast={toast}
                      chatTransferButton={chatTransferButton}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
    )
}